import { useEffect, useMemo, useState } from "react";
import { Button, ButtonGroup, Form, InputGroup } from "react-bootstrap";
import CloseButton from "react-bootstrap/CloseButton";
import DataTable from "react-data-table-component";

function Table(props) {
  const filteredData = props.filteredData;
  const clearSelected = props.clearSelected;
  const onRowClick = props.onRowClick;
  const onAggregateRowClick = props.onAggregateRowClick;
  const activeAggregation = props.activeAggregation;
  const onRowDoubleClicked = props.onRowDoubleClicked;
  const selectedFeatures = props.selectedFeatures;
  const activeBoundary = props.activeBoundary;

  const [formattedData, setFormattedData] = useState([]);
  const [tableMode, setTableMode] = useState("aggregate");

  const showAggregate = tableMode === "aggregate";
  const showSelected = tableMode === "selected";
  const showAll = tableMode === "all";

  const handleTableModeChange = (mode) => {
    if (mode === "selected" && selectedFeatures.length === 0) {
      return;
    }
    if (activeAggregation === "none" && mode === "aggregate") {
      setTableMode("aggregate");
    } else {
      setTableMode(mode);
    }
  };

  const handleRowClick = (row) => {
    if (showAggregate) {
      onAggregateRowClick(row);
    } else {
      onRowClick(row);
    }
  };

  useEffect(() => {
    // Reset the table mode if the selected features are cleared
    if (selectedFeatures.length === 0 && showSelected) {
      setTableMode("all");
    }
  }, [activeAggregation, selectedFeatures, showSelected]);

  const calculateAggregateData = () => {
    if (activeAggregation !== "none") {
      const mapper = {
        wards: "ward_id",
        tracts: "tract_geoid",
        tcac: "tcac_resource_area_2023",
        neighborhood: "neighborhood",
        zoning: "zoning",
        qct: "qct",
        dda: "dda",
      };

      let key = mapper[activeAggregation];
      const result = filteredData.features.reduce((acc, cur) => {
        const property = cur.properties[key];
        if (!acc[property]) {
          acc[property] = {
            label: property,
            totalAcreage: 0,
            count: 0,
            meanAcreage: 0,
            totalCapacity: 0,
            highComp: 0,
            medComp: 0,
            lowComp: 0,
            noComp: 0,
            shareHighComp: 0,
          };
        }
        acc[property].totalAcreage += cur.properties.acreage;
        acc[property].totalCapacity += cur.properties.total_capacity;
        acc[property].count += 1;
        acc[property].meanAcreage =
          acc[property].totalAcreage / acc[property].count;

        acc[property].highComp += cur.properties.priority_score === 4 ? 1 : 0;
        acc[property].medComp += cur.properties.priority_score === 3 ? 1 : 0;
        acc[property].lowComp += cur.properties.priority_score === 2 ? 1 : 0;
        acc[property].noComp += cur.properties.priority_score === 1 ? 1 : 0;
        acc[property].shareCompetitive =
          (acc[property].highComp + acc[property].medComp * 0.5) /
          acc[property].count;
        return acc;
      }, {});

      Object.keys(result).forEach((key) => {
        if (key === "null") {
          delete result[key];
        }
      });
      return Object.values(result);
    }
  };

  const aggregatedData = useMemo(calculateAggregateData, [
    activeAggregation,
    filteredData,
  ]);

  useEffect(() => {
    try {
      let formattedData = [];
      if (showAggregate) {
        formattedData = aggregatedData.map((feature) => feature || {});
      } else {
        formattedData = filteredData.features.map(
          (feature) => feature.properties || {}
        );
      }
      // round floats to 2 decimal places
      formattedData = formattedData.map((row) => {
        Object.keys(row).forEach((key) => {
          if (typeof row[key] === "number") {
            row[key] = Math.round(row[key] * 100) / 100;
          }
        });
        return row;
      });

      if (showSelected) {
        setFormattedData(filterSelected(formattedData, selectedFeatures));
      } else {
        setFormattedData(formattedData);
      }
    } catch (error) {
      console.error("Error processing GeoJSON data:", error);
    }
  }, [
    showSelected,
    selectedFeatures,
    showAggregate,
    aggregatedData,
    filteredData,
    showAll,
  ]);

  function filterSelected(filteredData, selectedFeatures) {
    if (selectedFeatures.length === 0) {
      return filteredData;
    } else {
      return filteredData.filter((item) => selectedFeatures.includes(item.apn));
    }
  }

  const customAggregateSort = (activeBoundary) => {
    if (activeBoundary === "wards") {
      return customSortWard;
    } else if (activeBoundary === "tcac") {
      return customSortOppCat;
    }
  };

  const customSortWard = (rowA, rowB) => {
    const wardOrder = [
      "WARD 1",
      "WARD 2",
      "WARD 3",
      "WARD 4",
      "WARD 5",
      "WARD 6",
      "WARD 7",
    ];
    return wardOrder.indexOf(rowA.label) - wardOrder.indexOf(rowB.label);
  };

  const customSortOppCat = (rowA, rowB) => {
    const oppCatOrder = [
      "Highest Resource",
      "High Resource",
      "Moderate Resource",
      "Low Resource",
      "Highest Segregation & Poverty",
    ];
    return oppCatOrder.indexOf(rowA.label) - oppCatOrder.indexOf(rowB.label);
  };

  const sortScores = (rowA, rowB) => {
    return rowA.total_points - rowB.total_points;
  };

  const aggregateColumns = [
    {
      name: activeAggregation === "wards" ? "Ward" : "Label",
      selector: (row) => row.label,
      sortable: true,
      reorder: true,
      defaultSortFieldId: activeAggregation === "wards" ? "Ward" : "Label",
      sortFunction: customAggregateSort(activeBoundary),
      defaultSortAsc: true,
    },
    {
      name: <div>Highly Competitive Sites</div>,
      selector: (row) => row.highComp,
      sortable: true,
      reorder: true,
    },
    {
      name: <div>Moderately Competitive Sites</div>,
      selector: (row) => row.medComp,
      sortable: true,
      reorder: true,
    },
    {
      name: <div>Somewhat Competitive Sites</div>,
      selector: (row) => row.lowComp,
      sortable: true,
      reorder: true,
    },
    {
      name: "Total Capacity",
      selector: (row) =>
        (Math.round(row.totalCapacity * 100) / 100).toLocaleString("en-US"),
      sortable: true,
      reorder: true,
    },
    {
      name: "Total Sites",
      selector: (row) => row.count,
      sortable: true,
      reorder: true,
    },
    {
      name: "Mean Site Size (acres)",
      selector: (row) => Math.round(row.meanAcreage * 100) / 100,
      sortable: true,
      reorder: true,
    },
  ];

  const parcelColumns = [
    {
      name: <div>Combined Score</div>,
      selector: (row) => Math.round(row.total_points * 100) / 100,
      sortable: true,
      defaultSortFieldId: "Combined Score",
      sortFunction: sortScores,
      defaultSortAsc: true,
      reorder: true,
    },
    {
      name: "LIHTC Score",
      selector: (row) => row.lihtc_total_points,
      sortable: true,
      reorder: true,
    },
    {
      name: "AHSC Score",
      selector: (row) => Math.round(row.ahsc_total_points * 100) / 100,
      sortable: true,
      reorder: true,
    },
    {
      name: "Current Use",
      selector: (row) => row.current_use,
      sortable: true,
      reorder: true,
    },
    {
      name: "Size (acres)",
      selector: (row) => row.acreage,
      sortable: true,
      reorder: true,
    },
    {
      name: "Total Capacity",
      selector: (row) => row.total_capacity.toLocaleString("en-US"),
      sortable: true,
      reorder: true,
    },
    {
      name: "Ownership",
      selector: (row) => row.publicly_or_privately_owned,
      sortable: true,
      reorder: true,
    },

    {
      name: "Proposed for Rezone",
      selector: (row) => row.rezone,
      sortable: true,
      reorder: true,
    },
    {
      name: "Zoning",
      selector: (row) =>
        row.zoning === null
          ? row.current_zoning === null
            ? "N/A"
            : row.current_zoning
          : row.zoning,
      sortable: true,
      reorder: true,
    },
  ];

  const ExpandableRowsComponent = ({ data }) => {
    const containerStyle = {
      display: "flex",
      justifyContent: "flex-start",
    };

    const tableStyle = {
      borderCollapse: "collapse",
    };

    const cellStyle = {
      border: "1px solid #dddddd",
      textAlign: "center",
      padding: "8px",
    };

    const headerCellStyle = {
      ...cellStyle,
      backgroundColor: "#f2f2f2",
      border: false,
      height: "30px",
    };

    const parcelTables = () => {
      return (
        <>
          <div style={containerStyle}>
            <table style={tableStyle}>
              <tbody
                style={{
                  border: "1px solid #dee2e6",
                  borderCollapse: "collapse",
                  fontSize: "14px",
                  textAlign: "center",
                }}
              >
                <tr>
                  <th style={headerCellStyle}>Parcel ID</th>
                  <th style={headerCellStyle}>{data.apn}</th>
                </tr>
                <tr>
                  <td>Address</td>
                  <td>
                    {data.site_address_or_street
                      ? data.site_address_or_street
                      : "N/A"}
                  </td>
                </tr>
                <tr>
                  <td>Current Use</td>
                  <td>{data.current_use ? data.current_use : "N/A"}</td>
                </tr>
                <tr>
                  <td>Size (acres)</td>
                  <td>{data.acreage ? data.acreage : "N/A"}</td>
                </tr>
                <tr>
                  <td>Total Capacity</td>
                  <td>{data.total_capacity ? data.total_capacity : "N/A"}</td>
                </tr>
                <tr>
                  <td>Ownership</td>
                  <td>
                    {data.publicly_or_privately_owned
                      ? data.publicly_or_privately_owned
                      : "N/A"}
                  </td>
                </tr>
                <tr>
                  <td>Proposed for Rezone?</td>
                  <td>{data.rezone ? data.rezone : "N/A"}</td>
                </tr>
                <tr>
                  <td>Zoning</td>
                  <td>
                    {data.zoning
                      ? data.zoning
                      : data.current_zoning
                      ? data.current_zoning
                      : "N/A"}
                  </td>
                </tr>
                <tr>
                  <td>TCAC Resource Area</td>
                  <td>
                    {data.tcac_resource_area_2023
                      ? data.tcac_resource_area_2023
                      : "N/A"}
                  </td>
                </tr>
                <tr>
                  <td>Qualified Census Tract</td>
                  <td>{data.qct2024_designated ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <td>Difficult to Develop Area</td>
                  <td>{data.dda ? "Yes" : "No"}</td>
                </tr>
              </tbody>
            </table>
            <table style={tableStyle}>
              <tbody
                style={{
                  border: "1px solid #dee2e6",
                  borderCollapse: "collapse",
                  fontSize: "14px",
                  textAlign: "center",
                }}
              >
                <tr>
                  <th style={headerCellStyle}>LIHTC Amenity Points</th>
                  <th style={headerCellStyle}>
                    {data.lihtc_total_points === 15
                      ? data.lihtc_total_points + " (Max)"
                      : data.lihtc_total_points}
                  </th>
                </tr>
                <tr>
                  <td>Transit</td>
                  <td>{data.lihtc_transit_points}</td>
                </tr>
                <tr>
                  <td>Park</td>
                  <td>{data.lihtc_park_points}</td>
                </tr>
                <tr>
                  <td>Library</td>
                  <td>{data.lihtc_lib_points}</td>
                </tr>
                <tr>
                  <td>Grocery</td>
                  <td>{data.lihtc_grocery_points}</td>
                </tr>
                <tr>
                  <td>School</td>
                  <td>{data.lihtc_school_points}</td>
                </tr>
                <tr>
                  <td>Senior Services</td>
                  <td>{data.lihtc_seniorserv_points}</td>
                </tr>
                <tr>
                  <td>Health Clinic</td>
                  <td>{data.lihtc_clinics_points}</td>
                </tr>
                <tr>
                  <td>Pharmacy</td>
                  <td>{data.lihtc_pharms_points}</td>
                </tr>
                <tr>
                  <td>TCAC</td>
                  <td>{data.lihtc_tcac_points}</td>
                </tr>
              </tbody>
            </table>
            <table style={tableStyle}>
              <tbody
                style={{
                  border: "1px solid #dee2e6",
                  borderCollapse: "collapse",
                  fontSize: "14px",
                  textAlign: "center",
                }}
              >
                <tr>
                  <th style={headerCellStyle}>AHSC Points</th>
                  <th style={headerCellStyle}>
                    {data.ahsc_total_points === 3
                      ? data.ahsc_total_points + " (Max)"
                      : data.ahsc_total_points}{" "}
                  </th>
                </tr>
                <tr>
                  <td>Grocery</td>
                  <td>{data.ahsc_groc_points}</td>
                </tr>
                <tr>
                  <td>Foodbank</td>
                  <td>{data.foodbank_ahsc_points}</td>
                </tr>
                <tr>
                  <td>Health Clinic</td>
                  <td>{data.ahsc_clinics_points}</td>
                </tr>
                <tr>
                  <td>Pharmacy</td>
                  <td>{data.ahsc_pharms_points}</td>
                </tr>
                <tr>
                  <td>Childcare</td>
                  <td>{data.ahsc_childcare_points}</td>
                </tr>
                <tr>
                  <td>Parks</td>
                  <td>{data.ahsc_park_points}</td>
                </tr>
                <tr>
                  <td>Community Center</td>
                  <td>{data.ahsc_comm_center_points}</td>
                </tr>
                <tr>
                  <td>Library</td>
                  <td>{data.ahsc_lib_points}</td>
                </tr>
                <tr>
                  <td>Education</td>
                  <td>{data.ahsc_education_points}</td>
                </tr>
                <tr>
                  <td>Post Office</td>
                  <td>{data.ahsc_education_points}</td>
                </tr>
                <tr>
                  <td>Banks/Finance</td>
                  <td>{data.ahsc_education_points}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      );
    };

    return parcelTables();
  };

  const convertToCSV = () => {
    const headers = Array.from(
      new Set(formattedData.flatMap((item) => Object.keys(item)))
    );

    // Remove 'centroid' and 'geometry' from headers
    const filteredHeaders = headers.filter(
      (header) => header !== "centroid" && header !== "geometry"
    );

    const csvContent =
      "data:text/csv;charset=utf-8," +
      filteredHeaders.join(",") +
      "\n" +
      formattedData
        .map((row) =>
          filteredHeaders
            .map((field) => (row[field] !== undefined ? row[field] : ""))
            .join(",")
        )
        .join("\n");
    return encodeURI(csvContent);
  };

  const downloadCSV = () => {
    const shouldExport = window.confirm(
      "Would you like to export this data table to a CSV?"
    );
    if (!shouldExport) {
      return;
    } else {
      const exportData = convertToCSV();
      if (exportData) {
        const label = showAggregate
          ? "aggregate"
          : showAll
          ? "sites"
          : "selected_sites";
        const link = document.createElement("a");
        link.setAttribute("href", exportData);
        link.setAttribute("download", label + "_export.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  const DataTableControls = () => {
    return (
      <>
        <Form>
          <InputGroup>
            <ButtonGroup marginTop="10px">
              <Button
                style={{ margin: "10px" }}
                variant="warning"
                size="sm"
                onClick={() => downloadCSV()}
              >
                Download Table Data
              </Button>
            </ButtonGroup>
            <Form.Check
              type="radio"
              style={{ margin: "10px" }}
              label="Show Summary Data"
              id="show-selected-site"
              onChange={() => handleTableModeChange("aggregate")}
              checked={showAggregate}
            />
            <Form.Check
              type="radio"
              style={{ margin: "10px" }}
              label={`Show Sites (${filteredData.features.length})`}
              id="show-all-sites"
              onChange={() => handleTableModeChange("all")}
              checked={showAll}
            />
            <Form.Check
              type="radio"
              style={{ margin: "10px" }}
              label={`Show Selected Sites (${selectedFeatures.length})`}
              id="show-selected-sites"
              disabled={selectedFeatures.length === 0}
              onChange={() => handleTableModeChange("selected")}
              checked={showSelected}
            />
            {selectedFeatures.length > 0 && (
              <>
                <span style={{ margin: "10px" }}>Clear Selected Sites</span>
                <CloseButton
                  id="clear-selected"
                  style={{ margin: "10px" }}
                  onClick={() => clearSelected()}
                />
              </>
            )}
          </InputGroup>
        </Form>
      </>
    );
  };
  return (
    <>
      <DataTableControls />
      <DataTable
        customStyles={{
          headCells: {
            style: {
              whiteSpace: "unset",
            },
          },
        }}
        columns={showAggregate ? aggregateColumns : parcelColumns}
        defaultSortFieldId="Competitiveness"
        defaultSortAsc={true}
        data={formattedData}
        dense={true}
        striped={true}
        responsive={true}
        pagination={true}
        highlightOnHover={true}
        onRowClicked={handleRowClick}
        onRowDoubleClicked={onRowDoubleClicked}
        expandableRows={!showAggregate}
        expandableRowsComponent={ExpandableRowsComponent}
      />
    </>
  );
}

export default Table;
