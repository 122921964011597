import { Button, ButtonGroup, Form } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import React, { useState } from "react";
import { Collapse } from "react-bootstrap";

function ControlPanel(props) {
  const amenityState = props.amenityState;
  const handleAmenityLayers = props.handleAmenityLayers;
  const zoningOptions = props.zoningOptions;
  const onBoundaryChange = props.onBoundaryChange;
  const onZoningChange = props.onZoningChange;
  const activeBoundary = props.activeBoundary;
  const onOverlayChange = props.onOverlayChange;
  const activeOverlay = props.activeOverlay;
  const handleAggregation = props.handleAggregation;
  const activeAggregation = props.activeAggregation;
  const transitState = props.transitState;
  const toggleTransitLayer = props.toggleTransitLayer;
  const togglePopup = props.togglePopup;
  const popupState = props.popupState;
  const onFilterChange = props.onFilterChange;
  const activeFilters = props.activeFilters;
  const resetFilters = props.resetFilters;

  const [open, setOpen] = useState(false);

  const handleTransitLayers = (layer) => {
    toggleTransitLayer(layer);
  };

  const handleFilter = (filter) => {
    onFilterChange(filter);
  };

  const BoundaryControls = () => {
    return (
      <>
        <Form>
          <h5>Map Layers</h5>
          <Form.Control
            as="select"
            size="sm"
            onChange={(e) => onOverlayChange(e.target.value)}
            value={activeOverlay}
            style={{ marginTop: "5px", marginBottom: "5px" }}
          >
            <option value="none">No Overlay</option>
            <option value="tcac">Opportunity Categories</option>
            <option value="qct">Qualified Census Tracts</option>
            <option value="dda">Difficult to Develop Areas</option>
            <option value="usda">USDA Rural Loan Areas</option>
          </Form.Control>
          <h5>Boundaries</h5>
          <Form.Control
            as="select"
            size="sm"
            onChange={(e) => onBoundaryChange(e.target.value)}
            value={activeBoundary}
            style={{ marginTop: "5px", marginBottom: "5px" }}
          >
            <option value="wards">Council Wards</option>
            <option value="tracts">Census Tracts</option>
          </Form.Control>

          <h5>Table Summary Level</h5>
          <Form.Control
            as="select"
            size="sm"
            style={{ marginTop: "5px", marginBottom: "5px" }}
            onChange={(e) => handleAggregation(e.target.value)}
            value={activeAggregation}
          >
            <option value="wards">Wards</option>
            <option value="tracts">Tracts</option>
            <option value="tcac">Opportunity Categories</option>
            <option value="dda">Difficult to Develop Areas</option>
            <option value="qct">Qualified Census Tract</option>
            <option value="neighborhood">Neighborhood</option>
          </Form.Control>
        </Form>
      </>
    );
  };

  const TransitControls = () => {
    return (
      <>
        <h5>Transit</h5>
        <ButtonGroup
          vertical={true}
          style={{
            width: "100%",
            marginTop: "5px",
          }}
        >
          <Form.Check
            style={{ marginBottom: "5px" }}
            label="Routes"
            id="show-routes"
            onClick={() => handleTransitLayers("routes")}
            defaultChecked={transitState.routes}
          />
          <Form.Check
            style={{ marginBottom: "5px" }}
            label="Stops"
            id="show-stops"
            onClick={() => handleTransitLayers("stops")}
            defaultChecked={transitState.stops}
          />
        </ButtonGroup>
      </>
    );
  };

  const AmenityControls = () => {
    return (
      <>
        <h5 onClick={() => setOpen(!open)}>Amenities</h5>
        {/* <Collapse in={open}> */}
        <ButtonGroup
          vertical={true}
          style={{
            width: "100%",
            marginTop: "5px",
          }}
        >
          <Form.Check
            style={{ marginBottom: "5px" }}
            label="Parks"
            id="show-parks"
            onClick={() => handleAmenityLayers("park")}
            defaultChecked={amenityState.park}
          />
          <Form.Check
            style={{ marginBottom: "5px" }}
            label="Libraries"
            id="show-libaries"
            onClick={() => handleAmenityLayers("library")}
            defaultChecked={amenityState.library}
          />
          <Form.Check
            style={{ marginBottom: "5px" }}
            label="Fire Stations"
            id="show-fire-stations"
            onClick={() => handleAmenityLayers("fire_station")}
            defaultChecked={amenityState.fire_station}
          />
          <Form.Check
            style={{ marginBottom: "5px" }}
            label="Schools"
            id="show-schools"
            onClick={() => handleAmenityLayers(["school", "college"])}
            defaultChecked={amenityState.school}
          />
          <Form.Check
            style={{ marginBottom: "5px" }}
            label="Banks"
            id="show-banks"
            onClick={() => handleAmenityLayers("bank")}
            defaultChecked={amenityState.bank}
          />
          <Form.Check
            style={{ marginBottom: "5px" }}
            label="Post Offices"
            id="show-post-offices"
            onClick={() => handleAmenityLayers("post_office")}
            defaultChecked={amenityState.post_office}
          />
          <Form.Check
            style={{ marginBottom: "5px" }}
            label={"Pharmacies/Medical Centers/Senior Services"
              .split("/")
              .join(" & ")}
            id="show-med-cal"
            onClick={() =>
              handleAmenityLayers(["medi_cal", "pharmacy", "senior_service"])
            }
            defaultChecked={amenityState.pharmacy}
          />
        </ButtonGroup>
        {/* </Collapse> */}
      </>
    );
  };

  const FilterControls = () => {
    return (
      <>
        <h5>Filters</h5>

        <ButtonGroup
          vertical={true}
          style={{
            width: "100%",
            marginTop: "5px",
          }}
        >
          <Form.Label>LIHTC Points ({activeFilters.lihtc_points}+)</Form.Label>
          <Form.Range
            min={0}
            max={15}
            step={5}
            label={activeFilters.lihtc_points}
            value={activeFilters.lihtc_points}
            onChange={(e) =>
              handleFilter({
                key: "lihtc_points",
                value: parseInt(e.target.value),
              })
            }
          ></Form.Range>
          <Form.Label>AHSC Points ({activeFilters.ahsc_points}+)</Form.Label>
          <Form.Range
            min={0}
            max={3}
            step={0.5}
            value={activeFilters.ahsc_points}
            onChange={(e) =>
              handleFilter({
                key: "ahsc_points",
                value: parseFloat(e.target.value),
              })
            }
          ></Form.Range>
          <Form.Label>
            Parcel Size <br />({activeFilters.acres}+ acres)
          </Form.Label>
          <Form.Range
            min={0}
            max={10}
            step={0.5}
            value={activeFilters.acres}
            onChange={(e) =>
              handleFilter({ key: "acres", value: parseFloat(e.target.value) })
            }
          ></Form.Range>
          <Form.Check
            style={{ marginBottom: "5px" }}
            id="filter-ownership"
            label="Publicly Owned"
            onClick={() => handleFilter("ownership")}
            defaultChecked={activeFilters.ownership}
          />

          <Form.Check
            style={{ marginBottom: "5px" }}
            label="Proposed for Rezone"
            id="filter-rezone"
            onClick={() => handleFilter("rezone")}
            defaultChecked={activeFilters.rezone}
          />
          <Form.Check
            style={{ marginBottom: "5px" }}
            label="Churches and Faith Sites"
            id="filter-tax-exempt"
            onClick={() => handleFilter("church_sites")}
            defaultChecked={activeFilters.church_sites}
          />
          <Form.Label>Zoning Designation</Form.Label>
          <div style={{ width: "100%" }}>
            <Multiselect
              selectedValues={activeFilters.zoning}
              options={zoningOptions}
              checkBox={true}
              displayValue="name"
              onSelect={(selectedList, selectedItem) =>
                onZoningChange(selectedItem, "add")
              }
              onRemove={(selectedList, selectedItem) =>
                onZoningChange(selectedItem, "remove")
              }
            />
          </div>

          <Button
            style={{ marginTop: "15px" }}
            size="sm"
            variant="warning"
            onClick={() => resetFilters()}
          >
            Clear Filters
          </Button>
        </ButtonGroup>
      </>
    );
  };

  const MapSettings = () => {
    return (
      <>
        <h5>Tool Settings</h5>
        <ButtonGroup
          vertical={true}
          style={{
            width: "100%",
            marginTop: "5px",
          }}
        >
          <Form.Switch
            style={{ marginBottom: "5px" }}
            label="Popup Toggle"
            id="popup-toggle"
            onChange={() => togglePopup()}
            checked={popupState}
          />
        </ButtonGroup>
      </>
    );
  };

  return (
    <>
      <FilterControls></FilterControls>
      <hr />
      <BoundaryControls></BoundaryControls>
      <hr />
      <AmenityControls></AmenityControls>
      <TransitControls></TransitControls>
      <hr />

      <MapSettings></MapSettings>
    </>
  );
}

export default ControlPanel;
