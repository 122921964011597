import * as turf from "@turf/turf";
import "bootstrap/dist/css/bootstrap.min.css";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import bakersfield_logo from "./assets/bakersfield.png";
import econw_logo from "./assets/econw_logo.png";
// Import local components
import ControlPanel from "./Components/ControlPanel";
import Table from "./Components/DataTable";
import MapHandler from "./Components/Map";
// Import data files
import parcels from "./data/FullSitesInv_Parcels.json";

function Dashboard() {
  // TODO: Implement Hover Highlight and Popup
  // TODO: Create Aggregated Data Table, Aggregated Layers, and Aggregated Popup
  // TODO: Refactor Feature Selection and Selected Feature Table
  // TODO: Finish Zoning Filter
  // TODO: Refactor Structure of App
  // TODO: Add Amenity Layers
  // TODO: Add Modal with Methodology

  const mapRef = useRef();

  const [activeBoundary, setActiveBoundary] = useState("wards");
  const [activeOverlay, setActiveOverlay] = useState("none");
  const [activeAggregation, setActiveAggregation] = useState("wards");

  const [popupState, setPopupState] = useState(true);
  const [popupInfo, setPopupInfo] = useState(null);
  const deactivatePopup = () => {
    setPopupInfo(null);
  };
  const togglePopup = () => {
    setPopupState(!popupState);
  };

  const [transitState, setTransitState] = useState({
    routes: false,
    stops: false,
  });

  const [activeFilters, setActiveFilters] = useState({
    church_sites: false,
    ownership: false,
    rezone: false,
    lihtc_points: 15,
    ahsc_points: 1,
    acres: 0.5,
    zoning: [],
  });

  const resetFilters = () => {
    setActiveFilters({
      church_sites: false,
      ownership: false,
      rezone: false,
      lihtc_points: 0,
      ahsc_points: 0,
      acres: 0,
      zoning: [],
    });
  };

  const [amenityState, setAmenityState] = useState({
    fire_station: false,
    park: false,
    school: false,
    pharmacy: false,
    post_office: false,
    library: false,
  });

  const handleAmenityLayers = (amenities) => {
    if (Array.isArray(amenities)) {
      amenities.forEach((amenity) => {
        setAmenityState((prevState) => ({
          ...prevState,
          [amenity]: !prevState[amenity],
        }));
      });
    } else {
      setAmenityState((prevState) => ({
        ...prevState,
        [amenities]: !prevState[amenities],
      }));
    }
  };

  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [highlightedFeature, setHighlightedFeature] = useState(null);

  // Clean up some variables in parcels data
  parcels.features.forEach((feature) => {
    feature.properties.rezone = feature.properties.rezone_flag ? "Yes" : "No";
    feature.properties.ownership_filter =
      feature.properties.publicly_or_privately_owned !== "Private"
        ? true
        : false;
    feature.properties.lihtc_total_points = Math.min(
      feature.properties.lihtc_total_points,
      15
    );
    feature.properties.ahsc_total_points = Math.min(
      feature.properties.ahsc_total_points,
      3
    );
    feature.properties.total_points =
      feature.properties.lihtc_total_points / 15 +
      feature.properties.ahsc_total_points / 3;
    feature.properties.centroid = calculateCentroid(feature);
    feature.properties.dda =
      feature.properties.dda_flag === null
        ? "Not in Difficult to Develop Area"
        : "Difficult to Develop Area";
    feature.properties.qct = feature.properties.qct2024_designated
      ? "Not in Qualified Census Tract"
      : "Qualified Census Tract";
    feature.properties.church_sites =
      feature.properties.faith_based_parcels === null ? false : true;
    if (feature.properties.church_sites) {
      feature.properties.current_use = "Church Site";
      feature.properties.total_capacity = 0;
      feature.properties.ownership_filter = false;
    }
  });

  function handleBoundaryChange(boundary) {
    setActiveBoundary(boundary);
  }

  function handleOverlayChange(overlay) {
    setActiveOverlay(overlay);
  }

  const toggleTransitLayer = (layer) => {
    setTransitState((prevState) => ({
      ...prevState,
      [layer]: !prevState[layer],
    }));
  };

  const handleAggregation = (aggregation) => {
    setActiveAggregation(aggregation);
  };

  const handleZoningChange = (selectedItem, action) => {
    setActiveFilters((prevState) => {
      let updatedZoning = [...prevState.zoning];
      if (action === "remove") {
        updatedZoning = updatedZoning.filter(
          (zoning) => zoning !== selectedItem
        );
      } else if (action === "add") {
        if (!updatedZoning.includes(selectedItem)) {
          updatedZoning = [...updatedZoning, selectedItem].flat();
        }
      }
      return { ...prevState, zoning: updatedZoning };
    });
  };

  const handleFilterChange = (filter) => {
    if (typeof filter === "object") {
      setActiveFilters((prevState) => ({
        ...prevState,
        [filter.key]: filter.value,
      }));
      return;
    } else {
      setActiveFilters((prevState) => ({
        ...prevState,
        [filter]: !prevState[filter],
      }));
    }
  };

  function calculateCentroid(feature) {
    let coordinates;
    if (feature.geometry !== undefined) {
      if (feature.geometry.type === "Polygon") {
        coordinates = feature.geometry.coordinates[0];
      } else if (feature.geometry.type === "MultiPolygon") {
        coordinates = feature.geometry.coordinates[0][0];
      }
      const polygon = turf.polygon([coordinates]);
      const centroid = turf.centroid(polygon);
      return centroid.geometry.coordinates;
    }
  }

  const centerMapOnFeature = (centroid, zoomTo) => {
    let map = mapRef.current.getMap();
    let currentZoom = map.getZoom();
    map.flyTo({
      center: [centroid[0], centroid[1]],
      zoom: currentZoom > zoomTo ? currentZoom : zoomTo,
      duration: 800,
    });
  };

  const handleFeatures = (feature) => {
    setHighlightedFeature(feature);
    if (popupState) {
      setPopupInfo({
        longitude: feature.properties.centroid[0],
        latitude: feature.properties.centroid[1],
      });
    } else if (popupInfo !== null) {
      setPopupInfo(null);
    }
  };

  const handleFeatureClick = (feature) => {
    if (feature !== null) {
      feature.properties.centroid = calculateCentroid(feature);
      if (selectedFeatures.includes(feature.properties.apn)) {
        setSelectedFeatures(
          selectedFeatures.filter((item) => item !== feature.properties.apn)
        );
      } else {
        setSelectedFeatures([feature.properties.apn, ...selectedFeatures]);
      }
      handleFeatures(feature);
      let zoom = mapRef.current.getMap().getZoom();
      if (zoom < 15 && !selectedFeatures.includes(feature.properties.apn)) {
        centerMapOnFeature(feature.properties.centroid, 15);
      }
    }
  };

  const handleRowClick = (row) => {
    const rowID = row.apn;
    const feature = parcels.features.find(
      (feature) => feature.properties.apn === rowID
    );
    handleFeatures(feature);
    centerMapOnFeature(feature.properties.centroid, 14);
  };

  const handleAggregateRowClick = (row) => {
    // console.log("Handle Aggregate Row Click Needs Fixing");
    // const rowID = row.label;
    // const feature = aggregatedData.filter((feature) => feature.label === rowID);
    // setHighlightedFeature(feature);
    // centerMapOnFeature(centroid, 12);
  };

  const clearSelectedFeatures = () => {
    setSelectedFeatures([]);
    setHighlightedFeature(null);
    setPopupInfo(null);
  };

  const resetTable = (row) => {
    const rowID = row.apn;
    const feature = parcels.features.find(
      (feature) => feature.properties.apn === rowID
    );
    setSelectedFeatures(
      selectedFeatures.filter((item) => item !== feature.properties.apn)
    );
  };

  const filterData = useCallback(() => {
    // Filter the data based on active filters
    const filteredFeatures = parcels.features.filter((feature) => {
      // Filter based on ownership
      if (activeFilters.ownership && !feature.properties.ownership_filter) {
        return false;
      }
      // Filter based on rezone
      if (activeFilters.rezone && !feature.properties.rezone_flag) {
        return false;
      }
      // Filter based on LIHTC points
      if (
        activeFilters.lihtc_points &&
        feature.properties.lihtc_total_points < activeFilters.lihtc_points
      ) {
        return false;
      }
      // Filter based on AHSC points
      if (
        activeFilters.ahsc_points &&
        feature.properties.ahsc_total_points < activeFilters.ahsc_points
      ) {
        return false;
      }
      // Filter based on acreage of site
      if (
        activeFilters.acres &&
        feature.properties.acreage < activeFilters.acres
      ) {
        return false;
      }
      // Filter based on church sites
      if (activeFilters.church_sites && !feature.properties.church_sites) {
        return false;
      }
      if (activeFilters.zoning.length > 0) {
        let zones = activeFilters.zoning.map((zone) => zone.name);
        if (
          !zones.includes(feature.properties.zoning) &&
          !zones.includes(feature.properties.current_zoning)
        ) {
          return false;
        }
      }
      // If none of the above conditions are met, include the feature
      return true;
    });

    return {
      type: "FeatureCollection",
      features: filteredFeatures,
    };
  }, [activeFilters]);

  const [filteredData, setFilteredData] = useState(filterData());

  useEffect(() => {
    const newData = filterData();
    setFilteredData(newData);
  }, [activeFilters, filterData]);

  const selectedData = useMemo(() => {
    if (selectedFeatures.length > 0) {
      return {
        type: "FeatureCollection",
        features: filteredData.features.filter((feature) =>
          selectedFeatures.includes(feature.properties.apn)
        ),
      };
    } else {
      return null;
    }
  }, [filteredData, selectedFeatures]);

  const uniqueZoningCodes = [
    ...new Set(
      parcels.features
        .map(
          (feature) =>
            feature.properties.current_zoning || feature.properties.zoning
        )
        .filter(Boolean)
    ),
  ];

  const zoningOptions = uniqueZoningCodes
    .map((code) => ({ name: code, id: code }))
    .sort((a, b) => a.name.localeCompare(b.name, undefined, { numeric: true }));

  return (
    <div style={{ height: "100vh" }}>
      <Container fluid>
        <Row height={"95vh"} style={{ marginTop: "10px" }}>
          <Col sm={2}>
            <h1 style={{ margin: "0px" }}>Bakersfield</h1>
            <h4 style={{ margin: "0px" }}>Opportunity Site Selector</h4>
            <hr />
            <div
              style={{
                maxHeight: "calc(85vh - 50px)",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <ControlPanel
                amenityState={amenityState}
                handleAmenityLayers={handleAmenityLayers}
                zoningOptions={zoningOptions}
                onZoningChange={handleZoningChange}
                resetFilters={resetFilters}
                onBoundaryChange={handleBoundaryChange}
                activeBoundary={activeBoundary}
                onOverlayChange={handleOverlayChange}
                activeOverlay={activeOverlay}
                handleAggregation={handleAggregation}
                activeAggregation={activeAggregation}
                activeFilters={activeFilters}
                onFilterChange={handleFilterChange}
                toggleTransitLayer={toggleTransitLayer}
                transitState={transitState}
                togglePopup={togglePopup}
                popupState={popupState}
              />
            </div>
          </Col>
          <Col sm={10}>
            <MapHandler
              amenityState={amenityState}
              parcels={filteredData}
              selectedData={selectedData}
              highlightedFeature={highlightedFeature}
              selectedFeatures={selectedFeatures}
              showPopup={popupState}
              popupInfo={popupInfo}
              deactivatePopup={deactivatePopup}
              activeBoundary={activeBoundary}
              activeOverlay={activeOverlay}
              transitState={transitState}
              mapRef={mapRef}
              onSingleClick={handleFeatureClick}
            />
            <Row>
              <Col overflow="scroll">
                <Table
                  height={"20vh"}
                  filteredData={filteredData}
                  selectedFeature={highlightedFeature}
                  activeAggregation={activeAggregation}
                  handleAggregation={handleAggregation}
                  activeBoundary={activeBoundary}
                  selectedFeatures={selectedFeatures}
                  clearSelected={clearSelectedFeatures}
                  onRowClick={handleRowClick}
                  onRowDoubleClicked={resetTable}
                  onAggregateRowClick={handleAggregateRowClick}
                ></Table>
              </Col>
              <Row>
                <Col style={{ textAlign: "left" }}>
                  <a href="https://www.bakersfieldcity.us/">
                    <img
                      id="bakersfield-logo"
                      className="footer"
                      src={bakersfield_logo}
                      height={"35px"}
                      alt="Bakersfield Logo."
                    ></img>
                    Created for the City of Bakersfield
                  </a>
                </Col>
                <Col style={{ textAlign: "right" }}>
                  <a href="https://econw.com/">Technology by</a>
                  {"  "}
                  <a href="https://econw.com/">
                    <img
                      id="econw-logo"
                      className="footer"
                      src={econw_logo}
                      alt="ECOnorthwest Logo."
                      height={"25px"}
                      style={{ align: "center" }}
                    ></img>
                  </a>
                </Col>
              </Row>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Dashboard;
