import * as turf from "@turf/turf";
import "bootstrap/dist/css/bootstrap.min.css";
import * as d3 from "d3";
import "mapbox-gl/dist/mapbox-gl.css";
import * as React from "react";
import { Layer, Map, NavigationControl, Popup, Source } from "react-map-gl";
import ddaBoundaries from "../data/Bakersfield_DDA.json";
import opportunityLayer from "../data/Bakersfield_TCAC_Zones.json";
import tractBoundaries from "../data/Bakersfield_Tracts.json";
import wardBoundaries from "../data/Bakersfield_Wards.json";
import { Col, Row } from "react-bootstrap";

import ineligibleUSDA from "../data/Bakersfield_USDA_MFH_DirectLoan_Uneligible.json";
import cityBoundary from "../data/City_Limits.json";
import transitRoutes from "../data/GET_routes.json";
import transitStops from "../data/GET_stops.json";
import wardLabels from "../data/ward_labels.json";
import amenities from "../data/amenities_input.json";

function MapHandler(props) {
  const amenityState = props.amenityState;
  const mapboxtoken =
    "pk.eyJ1Ijoic3BlbmNlci1rZWF0aW5nIiwiYSI6ImNsb3J1ZGw0NTB0Z3cya251ZWpyOW5lZzAifQ.SjEEqa7qNf6XkxG1M6MUMw";
  const parcels = props.parcels;
  const transitState = props.transitState;
  const selectedData = props.selectedData;
  const highlightedFeature = props.highlightedFeature;
  const onSingleClick = props.onSingleClick;
  const activeBoundary = props.activeBoundary;
  const activeOverlay = props.activeOverlay;
  const deactivatePopup = props.deactivatePopup;
  const showPopup = props.showPopup;
  const popupInfo = props.popupInfo;

  const mapRef = props.mapRef;
  const interactiveLayerIds = ["parcel-layer"];

  const initialView = {
    latitude: 35.3436,
    longitude: -119.0205,
    zoom: 10.5,
  };

  const [viewPort, setViewPort] = React.useState(initialView);

  const [currentZoom, setCurrentZoom] = React.useState(viewPort.zoom);

  const handleViewportChange = (event) => {
    const newViewPort = { ...event.viewState };
    if (newViewPort.zoom !== currentZoom) {
      setCurrentZoom(newViewPort.zoom);
    }
    setViewPort(newViewPort);
  };

  // invert the ineligible USDA boundary
  const world = [
    [
      [-180, 90],
      [-180, -90],
      [180, -90],
      [180, 90],
      [-180, 90],
    ],
  ];

  const eligibleUSDA = turf.difference(
    turf.polygon(world),
    ineligibleUSDA.features[0]
  );

  const handleSingleClick = (event) => {
    if (event.features.length > 0) {
      const clickedFeature = event.features.find(
        (feature) => feature.layer.id === "parcel-layer"
      );
      onSingleClick(clickedFeature);
    } else {
      deactivatePopup();
    }
  };

  tractBoundaries.features.forEach((feature) => {
    feature.properties.label = feature.properties.GEOID;
  });

  wardBoundaries.features.forEach((feature) => {
    feature.properties.label = feature.properties.WARD_ID;
  });

  const getBoundaryShape = () => {
    const mapper = {
      tracts: tractBoundaries,
      wards: wardBoundaries,
    };
    if (activeBoundary !== null) {
      return mapper[activeBoundary];
    }
  };

  const getOverlayShape = () => {
    const mapper = {
      qct: tractBoundaries,
      dda: ddaBoundaries,
      tcac: opportunityLayer,
      usda: eligibleUSDA,
    };
    if (activeOverlay !== null) {
      return mapper[activeOverlay];
    }
  };

  const boundaryLayer = getBoundaryShape();
  const overlayLayerData = getOverlayShape();

  const parcelShapeStyle = (visibility) => {
    return {
      layout: {
        visibility: visibility ? "visible" : "none",
      },
      id: "parcel-layer",
      type: "fill",
      paint: {
        "fill-color": [
          "interpolate",
          ["exponential", 2],
          ["get", "total_points"],
          0,
          "rgba(0, 255, 0, 0.85)",
          2,
          "rgba(25, 25, 112, 0.85)",
        ],
        "fill-opacity": 1,
        "fill-outline-color": "#000000",
      },
    };
  };

  const parcelHeatmapStyle = (visibility) => {
    return {
      layout: {
        visibility: visibility ? "visible" : "none",
      },
      id: "parcel-heatmap-layer",
      type: "heatmap",
      paint: {
        "heatmap-weight": [
          "interpolate",
          ["exponential", 2],
          ["get", "total_points"],
          0,
          0,
          1,
          0.1,
          2,
          1,
        ],
        "heatmap-intensity": 1,
        "heatmap-color": [
          "interpolate",
          ["linear"],
          ["heatmap-density"],
          0,
          "rgba(0, 255, 0, 0)",
          0.5,
          "rgba(144, 238, 144, 1)",
          1,
          "rgba(25, 25, 112, 1)",
        ],
        "heatmap-radius": 15,
        "heatmap-opacity": 0.8,
      },
    };
  };

  const BoundaryStyle = (visibility) => {
    return {
      layout: { visibility: "visible" },
      id: "aggregate-layer",
      type: "line",
      paint: {
        "line-color": "black",
      },
    };
  };

  const OverlayStyle = () => {
    const visibility = true;
    const overlay = activeOverlay;
    if (overlay === "none") {
      return {
        layout: { visibility: "none" },
        id: "overlay-layer",
        type: "fill",
        paint: {
          "fill-color": "rgba(0, 0, 0, 0)",
          "fill-opacity": 0,
          "fill-outline-color": "#000000",
        },
      };
    }
    if (overlay === "qct") {
      return {
        layout: { visibility: visibility ? "visible" : "none" },
        id: "overlay-layer",
        type: "fill",
        paint: {
          "fill-color": [
            "case",
            ["get", "qct2024_designated"],
            "rgba(0, 0, 255, 0.5)",
            "rgba(0, 0, 0, 0)",
          ],
          "fill-opacity": ["case", ["get", "qct2024_designated"], 1, 0],
          "fill-outline-color": "#000000",
        },
      };
    }
    if (overlay === "dda") {
      return {
        layout: { visibility: visibility ? "visible" : "none" },
        id: "overlay-layer",
        type: "fill",
        paint: {
          "fill-color": "rgba(160, 82, 45, 0.5)",
          "fill-opacity": 0.5,
          "fill-outline-color": "#000000",
        },
      };
    }
    if (overlay === "tcac") {
      // ("#632E5A", "#885453", "#AE794C", "#D3A045", "#F9C63E")

      return {
        layout: { visibility: visibility ? "visible" : "none" },
        id: "overlay-layer",
        type: "fill",
        paint: {
          "fill-color": [
            "match",
            ["get", "oppcat"],
            "Highest Resource",
            "rgba(99, 46, 90, 0.50)",
            "High Resource",
            "rgba(136, 84, 83, 0.50)",
            "Moderate Resource",
            "rgba(174, 121, 76, 0.50)",
            "Low Resource",
            "rgba(211, 160, 69, 0.50)",
            "High Segregation & Poverty",
            "rgba(249, 198, 62, 0.50)",
            "rgba(255, 255, 255, 0.50)",
          ],
          "fill-opacity": 1,
          "fill-outline-color": "#000000",
        },
      };
    }
    if (overlay === "usda") {
      return {
        layout: { visibility: visibility ? "visible" : "none" },
        id: "overlay-layer",
        type: "fill",
        paint: {
          "fill-color": "rgba(60, 179, 113, 0.5)",
          "fill-opacity": 0.5,
          "fill-outline-color": "#000000",
        },
      };
    }
  };

  const handleOverlayLegend = () => {
    const overlay = activeOverlay;
    if (overlay === "none") {
      return <></>;
    }
    if (overlay === "qct") {
      return (
        <div
          key="qct-legend"
          style={{
            textAlign: "left",
            marginLeft: "5px",
            marginRight: "5px",
            // width: "100%",
          }}
        >
          <span
            className="rounded"
            style={{
              backgroundColor: "rgba(0, 0, 255, 0.5)",
              display: "inline-block",
              marginRight: "1px",
              marginLeft: "1px",
              width: "100%",
              color: "black",
              padding: "0 10px",
              fontSize: "16px",
              whiteSpace: "normal",
            }}
          >
            Qualified Census Tracts
          </span>
        </div>
      );
    }
    if (overlay === "dda") {
      return (
        <div
          key="dda-legend"
          style={{
            textAlign: "left",
            marginLeft: "5px",
            // width: "100%",
          }}
        >
          <span
            className="rounded"
            style={{
              backgroundColor: "rgba(160, 82, 45, 0.5)",
              display: "inline-block",
              marginRight: "1px",
              marginLeft: "1px",
              width: "100%",
              color: "black",
              padding: "0 10px",
              fontSize: "16px",
              whiteSpace: "normal",
            }}
          >
            Difficult to Develop Areas
          </span>
        </div>
      );
    }
    if (overlay === "usda") {
      return (
        <div
          key="usda-legend"
          style={{
            textAlign: "left",
            marginLeft: "5px",
            // width: "100%",
          }}
        >
          <span
            className="rounded"
            style={{
              backgroundColor: "rgba(60, 179, 113, 0.5)",
              display: "inline-block",
              marginRight: "1px",
              marginLeft: "1px",
              width: "100%",
              color: "black",
              padding: "0 10px",
              fontSize: "16px",
              whiteSpace: "normal",
            }}
          >
            USDA Rural Direct Loan Eligible Areas
          </span>
        </div>
      );
    }
    if (overlay === "tcac") {
      const colorMap = {
        "Highest Resource": "rgba(99, 46, 90, 0.50)",
        "High Resource": "rgba(136, 84, 83, 0.50)",
        "Moderate Resource": "rgba(174, 121, 76, 0.50)",
        "Low Resource": "rgba(211, 160, 69, 0.50)",
        "High Segregation & Poverty": "rgba(249, 198, 62, 0.50)",
      };
      let divs = [
        <h6 style={{ textAlign: "center", margin: "0" }}>
          Opportunity Categories
        </h6>,
      ];
      for (const [key, color] of Object.entries(colorMap)) {
        divs.push(
          <div
            key={key}
            style={{
              textAlign: "left",
              marginLeft: "5px",
              // width: "100%",
            }}
          >
            <span
              className="rounded"
              style={{
                backgroundColor: color,
                display: "inline-block",
                marginRight: "1px",
                marginLeft: "1px",
                marginTop: "5px",
                width: "100%",
                color: "black",
                padding: "0 10px",
                fontSize: "16px",
                whiteSpace: "normal",
              }}
            >
              {key}
            </span>
          </div>
        );
      }
      return divs;
    }
  };

  const ScoreLegend = () => {
    const scores = [0, 0.5, 1, 1.25, 1.5, 1.75, 2];
    const colorScale = d3
      .scaleLinear()
      .domain([0, 2])
      .range(["rgba(0, 255, 0, 0.85)", "rgba(25, 25, 112, 0.85)"]);

    return scores.map((score) => (
      <div
        key={score}
        style={{ textAlign: "left", marginLeft: "5px", marginTop: "2px" }}
      >
        <span
          className="rounded"
          style={{
            backgroundColor: colorScale(score),
            display: "inline-block",
            marginRight: "1px",
            marginLeft: "1px",
            width: "100%",
            color: score > 1 ? "white" : "black",
            padding: "0 10px",
            fontSize: "16px",
            whiteSpace: "normal",
          }}
        >
          {score} {score === 0 ? "points" : score === 2 ? "points" : ""}
        </span>
      </div>
    ));
  };

  const Legend = (props) => {
    const overlayDiv = handleOverlayLegend();
    const visible = true;
    if (visible) {
      return (
        <div
          style={{
            position: "absolute",
            textAlign: "center",
            width: "20%",
            top: "10px",
            left: "10px",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            padding: "5px",
            borderRadius: "5px",
            border: "1px solid #000000",
          }}
        >
          <Row>
            {/* <h5 style={{ textAlign: "center" }}>Legend</h5> */}
            <Col sm={12}>
              <h6 style={{ textAlign: "center" }}>Combined Score</h6>
              {/* <ColorBar /> */}
              <ScoreLegend />
              {activeOverlay !== "none" && <hr style={{ margin: "1px" }} />}
              {overlayDiv}
            </Col>
          </Row>
        </div>
      );
    } else {
      return <></>;
    }
  };

  // const Legend = (props) => {
  //   const visible = true;
  //   const overlayDiv = handleOverlayLegend();
  //   // const parcelDiv = handleParcelLegend();
  //   if (visible) {
  //     return (
  //       <div
  //         style={{
  //           position: "absolute",
  //           textAlign: "center",
  //           width: "20%",
  //           top: "10px",
  //           left: "10px",
  //           backgroundColor: "rgba(255, 255, 255, 0.8)",
  //           padding: "5px",
  //           borderRadius: "5px",
  //           border: "1px solid #000000",
  //         }}
  //       >
  //         <Row>
  //           <h5 style={{textAlign:"center"}}>Legend</h5>
  //           <Col sm={12}>
  //         <h6 style={{textAlign:"center"}}>Combined Score</h6>
  //         <ColorBar />
  //         {activeOverlay !== "none" && <hr />}
  //         {overlayDiv}
  //           </Col>
  //         </Row>
  //       </div>
  //     );
  //   } else {
  //     return <></>;
  //   }
  // };

  const layers = mapRef.current
    ? mapRef.current.getMap().getStyle().layers
    : [];
  const overlayLayer = layers.find((layer) => layer.id === "overlay-layer");
  if (overlayLayer !== undefined) {
  }

  const filteredAmenities = {
    ...amenities,
    features: amenities.features.filter(
      (feature) => amenityState[feature.properties.type]
    ),
  };

  const MapComponent = (
    <Map
      {...viewPort}
      onMove={handleViewportChange}
      style={{ height: "65vh", width: "100%" }}
      maxZoom={17}
      minZoom={10}
      ref={mapRef}
      // mapStyle="mapbox://styles/spencer-keating/clp4o0vky00cv01rcerkh52kh"
      mapStyle="mapbox://styles/mapbox/basic-v9"
      mapboxAccessToken={mapboxtoken}
      attributionControl={false}
      interactiveLayerIds={interactiveLayerIds}
      onClick={handleSingleClick}
      cursor="pointer"
    >
      <NavigationControl />

      <Legend visible={viewPort.zoom >= 12} />
      <Source type="geojson" data={cityBoundary}>
        <Layer
          id="city-boundary"
          type="line"
          paint={{
            "line-color": "#000000",
            "line-width": 1.5,
          }}
          beforeId="road_major_label"
        ></Layer>
      </Source>

      {activeBoundary === "wards" && (
        <>
          <Source type="geojson" data={wardLabels}>
            <Layer
              id="ward-labels"
              type="symbol"
              layout={{
                "text-field": ["get", "WARD_ID"],
                "text-size": 16,
                "text-anchor": "center",
                "text-justify": "center",
                "text-offset": [0, 0],
              }}
              paint={{
                "text-color": "#000000",
                "text-halo-color": "#ffffff",
                "text-halo-width": 3,
              }}
            ></Layer>
          </Source>
          ;
        </>
      )}

      <Source type="geojson" data={filteredAmenities}>
        <Layer
          id="amenities-points"
          type="symbol"
          filter={["!=", "$type", "Polygon"]}
          layout={{
            "icon-image": [
              "match",
              ["get", "type"],
              "fire_station",
              "fire-station-15",
              "library",
              "library-15",
              "pharmacy",
              "pharmacy-15",
              "post_office",
              "post-15",
              "school",
              "school-15",
              "medi_cal",
              "hospital-15",
              "bank",
              "bank-15",
              "senior_service",
              "hospital-15",
              "college",
              "college-15",
              "default-15",
            ],
            "icon-size": 1.25,
            "icon-allow-overlap": true,
          }}
          beforeId="parcel-heatmap-layer"
        />
        <Layer
          id="amenities-polygons"
          type="fill"
          filter={["!=", "$type", "Point"]}
          paint={{
            "fill-color": "#008000",
            "fill-outline-color": "yellow",
            "fill-opacity": 0.5,
          }}
          beforeId="road_major_label"
        />
      </Source>

      {boundaryLayer && (
        <Source type="geojson" data={boundaryLayer}>
          <Layer
            id="boundary-layer"
            {...BoundaryStyle(viewPort.zoom < 13)}
            beforeId="admin_country"
          ></Layer>
        </Source>
      )}

      {overlayLayerData && (
        <Source type="geojson" data={overlayLayerData}>
          <Layer
            id="overlay-layer"
            {...OverlayStyle()}
            beforeId="admin_country"
          ></Layer>
        </Source>
      )}

      <Source type="geojson" data={parcels}>
        <Layer
          {...parcelShapeStyle(viewPort.zoom >= 12)}
          beforeId="ward-labels"
        ></Layer>
        <Layer
          {...parcelHeatmapStyle(viewPort.zoom < 12)}
          beforeId="ward-labels"
        ></Layer>
      </Source>
      {selectedData && (
        <Source type="geojson" data={selectedData}>
          <Layer
            layout={{ visibility: viewPort.zoom >= 12 ? "visible" : "none" }}
            id="selected-feature"
            type="line"
            paint={{
              "line-color": "#FFFF00",
              "line-width": 2,
            }}
          ></Layer>
        </Source>
      )}

      {transitState.stops && (
        <Source id="stops" type="geojson" data={transitStops}>
          <Layer
            id="stops"
            type="circle"
            paint={{
              "circle-radius": viewPort.zoom > 13 ? 4 : 3,
              "circle-color": "#000000",
            }}
            beforeId="road_major_label"
          ></Layer>
        </Source>
      )}
      {transitState.routes && (
        <Source id="routes" type="geojson" data={transitRoutes}>
          <Layer
            id="routes"
            type="line"
            paint={{
              // get route_color from data
              "line-color": ["concat", "#", ["get", "route_color"]],
              "line-width": 3,
            }}
            beforeId={transitState.stops ? "stops" : "road_major_label"}
          ></Layer>
        </Source>
      )}

      {showPopup && popupInfo && (
        <Popup
          latitude={popupInfo.latitude}
          longitude={popupInfo.longitude}
          closeButton={false}
          closeOnClick={false}
        >
          <div>
            <h4>
              Combined Score:{" "}
              {highlightedFeature.properties.total_points.toFixed(1)}
            </h4>
            <h6>
              LIHTC Points: {highlightedFeature.properties.lihtc_total_points} /
              15
            </h6>
            <h6>
              AHSC Points:{" "}
              {highlightedFeature.properties.ahsc_total_points.toFixed(1)} / 3
            </h6>
            <p>
              Total Capacity: {highlightedFeature.properties.total_capacity}
              <br />
              Size: {highlightedFeature.properties.acreage} (acres)
              <br />
              Proposed for Rezone: {highlightedFeature.properties.rezone}
              <br />
              Current Use: {highlightedFeature.properties.current_use}
              <br />
              Ownership:{" "}
              {highlightedFeature.properties.publicly_or_privately_owned}
            </p>
          </div>
        </Popup>
      )}
    </Map>
  );

  return MapComponent;

  // return (
  //   <MapComponent
  //     // initialViewState={{ ...viewState }}
  //     viewState={viewState}
  //     onMove={(event) => setViewState(event.viewState)}
  //     style={{ height: "65vh", width: "100%" }}
  //     maxZoom={17}
  //     minZoom={10}
  //     ref={mapRef}
  //     mapStyle="mapbox://styles/spencer-keating/clp4o0vky00cv01rcerkh52kh"
  //     mapboxAccessToken={mapboxtoken}
  //     attributionControl={false}
  //     interactiveLayerIds={interactiveLayerIds}
  //     onClick={handleSingleClick}
  //     cursor="pointer"
  //   >
  //     <NavigationControl />
  //     {/* <AggregateLayer /> */}
  //     <ParcelLayer viewState={viewState} />
  //     {/* {boundaryData && (
  //       <>
  //         <Source type="geojson" data={boundaryData}>
  //           {activeBoundary === "wards" ? (
  //             <Layer {...wardStyle} beforeId="road_major_label"></Layer>
  //           ) : (
  //             <Layer
  //               id="boundary-layer"
  //               type="fill"
  //               paint={{
  //                 "fill-opacity": 0.0,
  //                 "fill-color": "#74c476", // nice green hex code #74c476
  //               }}
  //               beforeId={"road_major_label"}
  //             ></Layer>
  //           )}
  //           <Layer
  //             id="boundary-layer-outline"
  //             type="line"
  //             paint={{
  //               "line-color": "#000000",
  //               "line-width": 1.5,
  //             }}
  //             beforeId={"road_major_label"}
  //           ></Layer>
  //         </Source>
  //       </>
  //     )} */}
  //     {/* {activeBoundary === "wards" && (
  //       <>
  //         <Source type="geojson" data={wardLabels}>
  //           <Layer
  //             id="ward-labels"
  //             type="symbol"
  //             layout={{
  //               "text-field": ["get", "WARD_ID"],
  //               "text-size": 16,
  //               "text-anchor": "center",
  //               "text-justify": "center",
  //               "text-offset": [0, 0],
  //             }}
  //             paint={{
  //               "text-color": "#000000",
  //               "text-halo-color": "#ffffff",
  //               "text-halo-width": 3,
  //             }}
  //           ></Layer>
  //         </Source>
  //         ;
  //       </>
  //     )} */}
  //     {transitState.stops && (
  //       <Source id="stops" type="geojson" data={transitStops}>
  //         <Layer
  //           id="stops"
  //           type="circle"
  //           paint={{
  //             "circle-radius": viewState.zoom > 13 ? 2 : 3,
  //             "circle-color": "#000000",
  //           }}
  //         ></Layer>
  //       </Source>
  //     )}
  //     {transitState.routes && (
  //       <Source id="routes" type="geojson" data={transitRoutes}>
  //         <Layer
  //           id="routes"
  //           type="line"
  //           paint={{
  //             // get route_color from data
  //             "line-color": ["concat", "#", ["get", "route_color"]],
  //             "line-width": viewState.zoom > 13 ? 1 : 2,
  //           }}
  //         ></Layer>
  //       </Source>
  //     )}
  //     {popupInfo && (
  //       <Popup
  //         latitude={popupInfo.latitude}
  //         longitude={popupInfo.longitude}
  //         closeButton={false}
  //         closeOnClick={false}
  //       >
  //         <div>
  //           <h3>{highlightedFeature.properties.apn}</h3>
  //           <p>Size (acres): {highlightedFeature.properties.acreage}</p>
  //           <p>Rezone: {highlightedFeature.properties.rezone}</p>
  //           <p>
  //             Total Capacity: {highlightedFeature.properties.total_capacity}
  //           </p>
  //           {/* Add more properties as needed */}
  //         </div>
  //       </Popup>
  //     )}
  //   </MapComponent>
  // );
}

export default MapHandler;
